<template>
  <div>
    <h1 class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15 error-title">
      {{ $t("title.error.forbidden") }}
    </h1>
    <p class="font-size-h3 font-weight-light">
      {{ $t("page.forbidden.description") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Forbidden",
};
</script>

<style lang="scss" scoped>
.error-title {
  font-size: 150px;
}
</style>
