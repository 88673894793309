<template>
  <div>
    <h1 class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15" style="font-size: 150px">
      {{ $t("title.error.notFound") }}
    </h1>
    <p class="font-size-h3 font-weight-light">{{ $t("page.notFound.description") }}</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
